import moment from 'moment';
import { IClientConfigVars } from 'shared/interfaces/ClientConfig';

import {
  getThumborImageURL,
  Size,
  ThumborImageOptions,
} from 'src/common/getThumborImageURL';
import { AssetType } from 'src/components/GlintsPicture/interfaces';

export interface URLUtils {
  getAssetURL: (assetType: AssetType, name: string) => string;
  getGlintsAssetURL: (
    image: string,
    assetType: AssetType,
    size: Size
  ) => string;
  getImageAssetURL: (image: string, assetType: AssetType | string) => string;
  getOpenGraphURL: (image: string, assetType: AssetType) => string;
  getThumborImageURL: (options: ThumborImageOptions) => string;
  getResumeDownloadURL: (resumeFileName: string) => string;
}

export const createUrlUtils = (
  config: IClientConfigVars,
  isAllUseOssEnabled: boolean
) => {
  const getImageAssetURL = (image: string, assetType: AssetType) => {
    if (isAllUseOssEnabled) {
      return `//${config.S3_BUCKET_NAME}.oss-ap-southeast-1.aliyuncs.com/${assetType}/${image}`;
    }
    return `//${config.S3_BUCKET_NAME}.s3.amazonaws.com/${assetType}/${image}`;
  };

  const urlUtils: URLUtils = {
    getThumborImageURL,
    getAssetURL: (assetType, name) => {
      if (isAllUseOssEnabled) {
        return `//${config.ASSETS_DOMAIN}/${assetType}/${name}`;
      }
      return `//s3-ap-southeast-1.amazonaws.com/${config.S3_BUCKET_NAME}/${assetType}/${name}`;
    },
    getImageAssetURL,
    getResumeDownloadURL: (resumeFileName: string) => {
      /**
       * Label can be either resume/temp. Due to some unknown legacy decisions
       * resume is being stored in differnt places. This is a tech debt and will
       * be fixed properly in another task https://glints.atlassian.net/browse/GM-5189
       */
      let label = 'resume';

      const isTimeStampAtEnd = (resumeFileName: string) => {
        // temp folder resume have format firstname-lastname-unix_millisecond_timestamp.ext
        // Below code extract timestamp from file name
        const splittedFileName = resumeFileName.split('-');
        const endingOfFileNameWithExtension =
          splittedFileName[splittedFileName.length - 1];
        const possibleTimeStamp = endingOfFileNameWithExtension.split('.')[0];
        return moment(possibleTimeStamp, 'X', true).isValid();
      };
      if (isTimeStampAtEnd(resumeFileName)) {
        label = 'temp';
      }

      const queryParams = new URLSearchParams({ label, key: resumeFileName });

      return `${config.CANDIDATE_BASE}${
        config.API_BASE
      }/s3/download?${queryParams.toString()}`;
    },
    getGlintsAssetURL: (image, assetType, size) =>
      getThumborImageURL({
        size,
        src: getImageAssetURL(image, assetType),
        serverURL: config.THUMBOR_BASE,
      }),
    getOpenGraphURL: (image, assetType) =>
      getThumborImageURL({
        size: { width: 1200 },
        src: getImageAssetURL(image, assetType),
        serverURL: config.THUMBOR_BASE,
      }),
  };

  return urlUtils;
};
